/* eslint-disable @typescript-eslint/no-explicit-any */
import { useDebounce } from '@hospy/shared-components';
import {
	Async,
	resetStampBulkInvoices,
	setInvoiceToBulk,
	useAppDispatch,
	useAppSelector
} from '@hospy/store';
import { message } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';

export interface Props {
	appId: string;
	partnerIntegrationId: string;
	reservations: Async;
	getReservations: (payload: any) => any;
	setReservations: (payload: any) => any;
	actionCloseDetail?: (action: any) => any;
	hiddenButtonScheduleInvoice?: boolean;
	currencyDisplay?: string;
	detailChildren?: (
		selectedReservation: any,
		closeInvoiceDetail: any
	) => React.ReactElement | React.ReactElement[] | undefined;
	closeInvoiceDetail: () => void;
}

export const pmsFilterTypes = [
	{
		value: 'endDate',
		label: 'Fecha de Check out'
	},
	{
		value: 'startDate',
		label: 'Fecha de Check in'
	}
];

export const otaFilterTypes = [
	{
		value: 'checkOutDate',
		label: 'Fecha de Check out'
	},
	{
		value: 'checkInDate',
		label: 'Fecha de Check in'
	}
];

export const useInvoiceListFilters = ({ reservations, ...props }: Props) => {
	const dispatch = useAppDispatch();
	const [selectedDates, setSelectedDates] = useState<string[]>([
		moment().subtract(1, 'd').startOf('day').format('YYYY-MM-DDTHH:mm:ss'),
		moment().endOf('day').format('YYYY-MM-DDTHH:mm:ss')
	]);
	const [listing, setListing] = useState<string[] | undefined>(undefined);
	const [selectedFilterField, setSelectedFilterField] =
		useState<string>('endDate');
	const [searchValue, setSearchValue] = useState<string | null>(null);
	const [justReservationsInProcess, setJustReservationsInProcess] =
		useState<boolean>(false);
	const [page, setPage] = useState(1);
	const [selectedAdditionalFilters, setSelectedAdditionalFilters] = useState<
		string[]
	>([]);

	const { propertyIntegrations } = useAppSelector(({ user }) => user);
	const { stampBulk } = useAppSelector(
		({ partnerAccounting }) => partnerAccounting
	);

	const debouncedSearch = useDebounce(searchValue || '', 500);

	useEffect(() => {
		if (debouncedSearch && debouncedSearch.length > 3) {
			props.closeInvoiceDetail();
			setPage(1);
			setSelectedAdditionalFilters([]);
			setJustReservationsInProcess(false);
			executeFilter(`{"search":"${debouncedSearch}"}`);
		}
	}, [debouncedSearch]);

	useEffect(() => {
		if (!propertyIntegrations) return;
		const fromIntegration = propertyIntegrations.find(
			(integration) =>
				['ota', 'pms'].includes(integration.partnerIntegrationType) &&
				integration.appId === props.appId
		);
		let initialFieldField = 'endDate';
		if (fromIntegration?.partnerIntegrationType === 'pms')
			setSelectedFilterField('endDate');

		if (fromIntegration?.partnerIntegrationType === 'ota') {
			initialFieldField = 'checkInDate';
			setSelectedFilterField('checkInDate');
		}

		const startDate =
			selectedDates[0] ||
			moment()
				.utc(true)
				.subtract(1, 'd')
				.startOf('day')
				.format('YYYY-MM-DDTHH:mm:ss');

		const endDate =
			selectedDates[1] ||
			moment().utc(true).endOf('day').format('YYYY-MM-DDTHH:mm:ss');

		const filterQuery = generateFilterQuery(
			[startDate, endDate],
			selectedAdditionalFilters,
			initialFieldField,
			justReservationsInProcess,
			listing
		);

		executeFilter(filterQuery);
	}, [propertyIntegrations]);

	useEffect(() => {
		if (stampBulk.loading === 'succeeded') {
			message.info('Se iniciará el proceso de emisión.');
			dispatch(resetStampBulkInvoices());
			dispatch(setInvoiceToBulk([]));
			refresh();
		}

		if (stampBulk.loading === 'failed') {
			dispatch(resetStampBulkInvoices());
			message.error('No se pudo iniciar el proceso de emisión.');
		}
	}, [stampBulk.loading]);

	const updateSelectedDates = (start?: string, end?: string) => {
		props.closeInvoiceDetail();
		setSearchValue('');
		setPage(1);
		const startDate = moment(start)
			.utc(true)
			.startOf('day')
			.format('YYYY-MM-DDTHH:mm:ss');

		const endDate = moment(end)
			.utc(true)
			.endOf('day')
			.format('YYYY-MM-DDTHH:mm:ss');

		setSelectedDates([startDate, endDate]);

		const filterQuery = generateFilterQuery(
			[startDate, endDate],
			selectedAdditionalFilters,
			selectedFilterField,
			justReservationsInProcess,
			listing
		);

		executeFilter(filterQuery);
	};

	const updateFilterField = (key: string) => {
		props.closeInvoiceDetail();
		setSelectedFilterField(key);
		setSearchValue('');
		setPage(1);

		const filterQuery = generateFilterQuery(
			selectedDates,
			selectedAdditionalFilters,
			key,
			justReservationsInProcess,
			listing
		);

		executeFilter(filterQuery);
	};

	const refresh = () => {
		props.closeInvoiceDetail();
		if (!searchValue) {
			const filterQuery = generateFilterQuery(
				selectedDates,
				selectedAdditionalFilters,
				selectedFilterField,
				justReservationsInProcess,
				listing
			);

			executeFilter(filterQuery);
			return;
		}
		dispatch(
			props.getReservations({
				filter: `{"search":"${searchValue}"}`,
				appId: props.appId,
				partnerIntegrationId: props.partnerIntegrationId,
				page
			})
		);
	};

	const searchByValue = (value: string | null) => {
		props.closeInvoiceDetail();
		setSearchValue(value || '');
		setPage(1);
		if (value === '') {
			dispatch(
				props.getReservations({
					filter: `{"${selectedFilterField}":{"$gte":ISODate('${selectedDates[0]}'), "$lt":ISODate('${selectedDates[1]}')}}`,
					appId: props.appId,
					partnerIntegrationId: props.partnerIntegrationId,
					page
				})
			);
		}

		return;
	};

	const swipeJustInvoiceInProcess = () => {
		props.closeInvoiceDetail();
		setSearchValue('');
		setSelectedAdditionalFilters([]);
		const _justReservationsInProcess = !justReservationsInProcess;
		setJustReservationsInProcess(_justReservationsInProcess);

		const filterQuery = generateFilterQuery(
			selectedDates,
			[],
			selectedFilterField,
			_justReservationsInProcess,
			listing
		);
		executeFilter(filterQuery);
	};

	const updatePage = (currentPage: number) => {
		props.closeInvoiceDetail();
		setPage(currentPage);
		if (searchValue !== '') {
			dispatch(
				props.getReservations({
					filter: `{"${selectedFilterField}":{"$gte":ISODate('${
						selectedDates[0]
					}'), "$lt":ISODate('${
						selectedDates[1]
					}')}${generateInProcessFilter(justReservationsInProcess)}}`,
					appId: props.appId,
					partnerIntegrationId: props.partnerIntegrationId,
					page: currentPage
				})
			);
		} else {
			const filterQuery = generateFilterQuery(
				selectedDates,
				selectedAdditionalFilters,
				selectedFilterField,
				justReservationsInProcess,
				listing
			);
			executeFilter(filterQuery, currentPage);
		}
	};

	const updateAdditionalFilters = (filters: string[]) => {
		props.closeInvoiceDetail();
		setSelectedAdditionalFilters(filters);
		setSearchValue('');
		setPage(1);
		setJustReservationsInProcess(false);
		const filterQuery = generateFilterQuery(
			selectedDates,
			filters,
			selectedFilterField,
			justReservationsInProcess,
			listing
		);
		executeFilter(filterQuery);
	};

	const executeFilter = (filterQuery: string, currentPage?: number) => {
		dispatch(
			props.getReservations({
				filter: filterQuery,
				appId: props.appId,
				partnerIntegrationId: props.partnerIntegrationId,
				page: currentPage || page
			})
		);
	};

	const changeListingSelected = (value: string | string[]) => {
		let listings: any = value;
		if (typeof value === 'object' && value.filter((e) => e).length > 0) {
			listings = value.filter((e) => e);
			setListing(value.filter((e) => e));
		} else {
			listings = undefined;
			setListing(undefined);
		}
		setSearchValue('');
		setPage(1);
		setJustReservationsInProcess(false);
		const filterQuery = generateFilterQuery(
			selectedDates,
			selectedAdditionalFilters,
			selectedFilterField,
			justReservationsInProcess,
			listings
		);
		executeFilter(filterQuery);
	};

	return {
		selectedDates,
		updateSelectedDates,
		loading: reservations.loading === 'pending',
		reservations: reservations.data?.data,
		updateFilterField,
		refresh,
		searchByValue,
		searchValue,
		justReservationsInProcess,
		swipeJustInvoiceInProcess,
		page,
		updatePage,
		selectedAdditionalFilters,
		updateAdditionalFilters,
		selectedFilterField,
		changeListingSelected,
		listing
	};
};

const generateInProcessFilter = (
	justReservationsInProcess: boolean,
	forcedJustReservationsInProcess?: boolean
) => {
	const currentJustReservationsInProcess =
		forcedJustReservationsInProcess !== undefined
			? forcedJustReservationsInProcess
			: justReservationsInProcess;
	const inProcessFilter = currentJustReservationsInProcess
		? `,"stampStatus":["rejected","failure","processing"]`
		: '';

	return inProcessFilter;
};

const generateFilterQuery = (
	dates: string[],
	filters: string[],
	filterField: string,
	justReservationsInProcess: boolean,
	listings: string[] | undefined = undefined
): string => {
	const dateFilter = `"${filterField}":{"$gte":ISODate('${dates[0]}'), "$lt":ISODate('${dates[1]}')}`;
	let filterStamp = '';
	let reservationStatusFilter = '';
	let inProcessFilter = '';
	let currencyFilter = '';
	let importFilter = '';
	let listingFilter = '';

	if (filters.length > 0) {
		const stampStatusFound = filters.find(
			(filter) =>
				filter === 'draft' ||
				filter === 'rejected' ||
				filter === 'failure' ||
				filter === 'accepted' ||
				filter === 'not-billed'
		);
		if (stampStatusFound) {
			filterStamp = `,"stampStatus":["${stampStatusFound}"]`;
		}

		reservationStatusFilter = filters
			.filter((filter) => ['checked_in', 'checked_out'].includes(filter))
			.map((filter) => `"${filter}"`)
			.join(', ');
		if (reservationStatusFilter !== '')
			reservationStatusFilter = `, "reservationStatus": [${reservationStatusFilter}]`;

		const currencyFilterFound = filters.find(
			(filter) => filter === 'cop' || filter === 'usd'
		);
		if (currencyFilterFound)
			currencyFilter = `, "currency": "${currencyFilterFound.toUpperCase()}"`;

		const importFilterFound = filters.find((filter) =>
			filter.includes('totalImported')
		);
		if (importFilterFound)
			if (importFilterFound === 'totalImported')
				importFilter = `, "totalImported": true`;
			else importFilter = `, "totalImported": {$ne: true}`;
	} else {
		inProcessFilter = `${generateInProcessFilter(
			justReservationsInProcess
		)}`;
	}

	if (listings) {
		listingFilter = `, "listing": "${encodeURIComponent(
			listings.join(',')
		)}"`;
	}

	return `{${dateFilter}${filterStamp}${reservationStatusFilter}${inProcessFilter}${currencyFilter}${importFilter}${listingFilter}}`;
};
